<template>
  <v-row>
    <v-col cols="12">
      <v-row>
        <v-col cols="12" sm="12" md="6" lg="2">
          <v-menu
            v-model="menuStartDate"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="290px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                outlined
                flat
                dense
                background-color="white"
                v-model="params.startDate"
                append-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
                label="Start Date"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="params.startDate"
              @input="menuStartDate = false"
            ></v-date-picker>
          </v-menu>
        </v-col>
        <v-col cols="12" sm="12" md="6" lg="2">
          <v-menu
            v-model="menuEndDate"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="290px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                outlined
                flat
                dense
                background-color="white"
                v-model="params.endDate"
                append-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
                label="End Date"
              ></v-text-field>
            </template>
            <v-date-picker v-model="params.endDate" @input="menuEndDate = false"></v-date-picker>
          </v-menu>
        </v-col>
        <v-col cols="12" sm="12" md="6" lg="2">
          <v-checkbox label="All" v-model="params.all"></v-checkbox>
        </v-col>
        <v-col cols="12" sm="12" md="6" lg="2">
          <v-btn color="primary" @click="search" class="mr-2">
            <v-icon>
              mdi-magnify
            </v-icon>
          </v-btn>
          <v-btn color="success" @click="excel">
            <v-icon>
              mdi-file-excel
            </v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </v-col>
    <v-col cols="12">
      <v-simple-table dense>
        <template v-slot:default>
          <thead>
            <tr>
              <th rowspan="2" class="text-center">
                No
              </th>
              <th rowspan="2" class="text-center">
                Mfr
              </th>
              <th rowspan="2" class="text-center">
                Part#
              </th>
              <th rowspan="2" class="text-center">
                Part Name
              </th>
              <th rowspan="2" class="text-center">
                Description
              </th>
              <th rowspan="2" class="text-center">
                Warehouse
              </th>
              <th rowspan="2" class="text-center">
                Avarage Price
              </th>
              <th colspan="4" class="text-center">
                QTY
              </th>
              <th colspan="4" class="text-center">
                Total In Out
              </th>
            </tr>
            <tr>
              <th class="text-center">Begin</th>
              <th class="text-center">In</th>
              <th class="text-center">Out</th>
              <th class="text-center">End</th>
              <th class="text-center">Begin</th>
              <th class="text-center">In</th>
              <th class="text-center">Out</th>
              <th class="text-center">End</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, i) in items" :key="i + 'a'">
              <td>{{ i + 1 }}</td>
              <td>{{ item.mfr }}</td>
              <td>{{ item.part_num }}</td>
              <td>{{ item.part_name }}</td>
              <td>{{ item.part_desc }}</td>
              <td>{{ item.wh }}</td>
              <td>{{ formatPrice(item.price_id) }}</td>
              <td>{{ item.qty_begin }}</td>
              <td>{{ item.qty_in }}</td>
              <td>{{ item.qty_out }}</td>
              <td>{{ item.qty_end }}</td>
              <td>{{ formatPrice(item.price_id * item.qty_begin) }}</td>
              <td>{{ formatPrice(item.price_id * item.qty_in) }}</td>
              <td>{{ formatPrice(item.price_id * item.qty_out) }}</td>
              <td>{{ formatPrice(item.price_id * item.qty_end) }}</td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-col>
  </v-row>
</template>

<script>
import moment from "moment";
import fileDownload from "js-file-download";

export default {
  name: "report-transaction-inventory-summary",
  data() {
    return {
      params: {
        startDate: moment()
          .startOf("month")
          .format("yyyy-MM-DD"),
        endDate: moment()
          .endOf("month")
          .format("yyyy-MM-DD"),
        all: false,
      },
      menuStartDate: false,
      menuEndDate: false,
      items: [],
      headers: [
        {
          id: 1,
          text: "No",
          align: "center",
          sortable: false,
          value: "no",
          divider: true,
          class: "light-blue lighten-2 font-weight-black",
        },
        {
          id: 1,
          text: "MFR",
          sortable: false,
          value: "mfr",
          divider: true,
          class: "light-blue lighten-2 font-weight-black",
        },
        {
          id: 1,
          text: "Part#",
          sortable: false,
          value: "part_num",
          divider: true,
          class: "light-blue lighten-2 font-weight-black",
        },
        {
          id: 1,
          text: "Part Name",
          sortable: false,
          value: "part_name",
          divider: true,
          class: "light-blue lighten-2 font-weight-black",
        },
        {
          id: 1,
          text: "Part Desc",
          sortable: false,
          value: "part_desc",
          divider: true,
          class: "light-blue lighten-2 font-weight-black",
        },
        {
          id: 1,
          text: "Transaction Type",
          sortable: false,
          value: "txn_type",
          divider: true,
          class: "light-blue lighten-2 font-weight-black",
        },
        {
          id: 1,
          text: "Warehouse",
          sortable: false,
          value: "wh",
          divider: true,
          class: "light-blue lighten-2 font-weight-black",
        },
        {
          id: 1,
          text: "Inventory Location",
          sortable: false,
          value: "wh_name",
          divider: true,
          class: "light-blue lighten-2 font-weight-black",
        },
        {
          id: 1,
          text: "QTY IN",
          sortable: false,
          value: "qty_in",
          divider: true,
          class: "light-blue lighten-2 font-weight-black",
        },
        {
          id: 1,
          text: "QTY OUT",
          sortable: false,
          value: "qty_out",
          divider: true,
          class: "light-blue lighten-2 font-weight-black",
        },
        {
          id: 1,
          text: "Unit Price IDR",
          sortable: false,
          value: "price",
          divider: true,
          class: "light-blue lighten-2 font-weight-black",
        },
        {
          id: 1,
          text: "Total Price IDR",
          sortable: false,
          value: "total",
          divider: true,
          class: "light-blue lighten-2 font-weight-black",
        },
        {
          id: 1,
          text: "Date",
          sortable: false,
          value: "date",
          divider: true,
          class: "light-blue lighten-2 font-weight-black",
        },
        {
          id: 1,
          text: "Reference Doc",
          sortable: false,
          value: "ref_doc",
          divider: true,
          class: "light-blue lighten-2 font-weight-black",
        },
        {
          id: 1,
          text: "Remark",
          sortable: false,
          value: "remark",
          divider: true,
          class: "light-blue lighten-2 font-weight-black",
        },
        {
          id: 1,
          text: "Last Balance",
          align: "center",
          sortable: false,
          value: "lastBlc",
          divider: true,
          class: "light-blue lighten-2 font-weight-black",
        },
      ],
    };
  },
  methods: {
    async excel() {
      this.$store.commit("SET_LOADER", true);
      await this.$store
        .dispatch("report/transactionInventorySummaryExcel", this.params)
        .then(response => {
          fileDownload(response.data, `transaction-inventory.xlsx`);
          this.$store.commit("SET_LOADER", false);
        })
        .catch(() => {
          this.$store.dispatch("toast", {
            type: "error",
            message: "Ada masalah dengan aplikasi, mohon hubungi admin aplikasi",
          });
          this.$store.commit("SET_LOADER", false);
        });
    },
    async search() {
      this.$store.commit("SET_LOADER", true);
      await this.$store
        .dispatch("report/transactionInventorySummary", this.params)
        .then(response => {
          this.items = response.data;
          this.$store.commit("SET_LOADER", false);
        })
        .catch(() => {
          this.$store.dispatch("toast", {
            type: "error",
            message: "Ada masalah dengan aplikasi, mohon hubungi admin aplikasi",
          });
          this.$store.commit("SET_LOADER", false);
        });
    },
    formatPrice(value) {
      let val = (value / 1).toFixed(2).replace(".", ",");
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },
  },
};
</script>

<style></style>
